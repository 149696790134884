import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { Provider } from 'react-redux';
import { getStore } from './store';

import './common/css/font-awesome.min.css';
import './common/css/bootstrap.min.css';
import './common/css/custom.css';
import './common/css/responsive.css';
import './common/css/slick.css';

import Home from './components/Home/Home';
import FavouriteProducts from "./components/Products/FavouriteProducts";
import Products from './components/Products/Products';
import ProductDetail from './components/Products/ProductDetail';
import Checkout from './components/Checkout/Checkout';
import Thankyou from './components/Checkout/Thankyou';
import Myaccount from './components/Myaccount/Myaccount';
import Logout from './components/Myaccount/Logout';
import Orders from './components/Myaccount/Orders';
import Mypromotions from './components/Myaccount/Mypromotions';
import Rewards from './components/Myaccount/Rewards';
import Myvoucher from './components/Myaccount/Myvoucher';
import ReferFriends from './components/Myaccount/ReferFriends';
import Mynotifications from './components/Myaccount/Notifications';

import Account from './components/Account/Account';
import Resetpassword from './components/Account/Resetpassword';
import Pages from './components/Pages/Pages';
import ContactUs  from './components/Pages/ContactUs';
import Outlets  from './components/Pages/Outlets';

import Page404 from './Page404';

const store = getStore(); 

render( 
	<Provider store={store}>

		<Router>
			<Switch>
				<Route exact path='/' component={Home}/>
				<Route path={"/products/:slugType/:slugValue/:proSlug"} component={ProductDetail}/>
				<Route path={"/products/:slugType/:slugValue"} component={Products}/>
				<Route path="/products" component={Products} />
				<Route path="/favourite" component={FavouriteProducts} />
				<Route path="/checkout" component={Checkout} />
				<Route path="/thankyou/:orderId" component={Thankyou} />				
				<Route path="/mypromotions" component={Mypromotions} />
				<Route path="/myorders" component={Orders} />
				<Route path="/myaccount" component={Myaccount} />
				<Route path="/rewards" component={Rewards} />
				<Route path="/myvouchers" component={Myvoucher} />
				<Route path="/referfriends" component={ReferFriends} />
				<Route path="/notifications" component={Mynotifications} />
				<Route path="/account/activation/:activationKey" component={Account} />
				<Route path="/account/resetpassword/:resetKey" component={Resetpassword} />
				<Route path="/about-us" component={Pages} />
				<Route path="/contact-us" component={ContactUs} />
				<Route path="/locations" component={Outlets} />
				<Route path="/logout" component={Logout} />				
				<Route path="/page/:page_slug" component={Pages} />
				<Route component={Page404} />
			</Switch>
		</Router>

	</Provider>,

document.getElementById('root'),
);
