/* eslint-disable */
import React, { Component } from 'react';
import cookie from 'react-cookies';
import { connect } from 'react-redux';
import { Link} from 'react-router-dom';
import Slider from "react-slick";
import OwlCarousel from 'react-owl-carousel';
import "../../common/css/owl.carousel.css";
import Axios from 'axios';

import Parser from "html-react-parser";
	
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import HomeBanner from "./HomeSubCompo/HomeBanner";
import FeaturedProducts from "./HomeSubCompo/FeaturedProducts";
import PromotionProducts from "./HomeSubCompo/PromotionProducts";
import MenuDNavigation from "./HomeSubCompo/MenuHomeNavigation";

import { appId, apiUrl, deliveryId, madbarId, pickupId, timThumpUrl, reservationId, cateringId} from "../Helpers/Config";

import { stripslashes} from "../Helpers/SettingHelper";

import { GET_STATIC_BLOCK, GET_MENU_NAVIGATION } from '../../actions';

import deliveryImg from "../../common/images/delivery.svg";
import takeawayImg from "../../common/images/takeaway.png";

//import joinusImg from "../../common/images/join-lhs.png";
import joinusIcon from "../../common/images/join.png";

import user from "../../common/images/user.png";
import { callImage } from "../Helpers/SettingHelper";
import Testimonial from "./HomeSubCompo/Testimonial";

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
 

class Home extends Component {

	constructor(props) {

		super(props);
		this.state = {
		  users: [], nextavail: '', cartTriggerFlg: 'No', home_top_note: [], our_story:[], whatshappenings_source:'', whatshappenings:[],photoIndex:"",selectedNavigation: "",
      selectedNavigationFilter: "",selectedMainCatId:
        cookie.load("selectedMainCatId") !== "" &&
        cookie.load("selectedMainCatId") !== undefined &&
        cookie.load("selectedMainCatId") !== "undefined"
          ? cookie.load("selectedMainCatId")
          : "",
		}
		var outltIdTxt = cookie.load("orderOutletId");
    	this.props.getMenuNavigationList(outltIdTxt);
		cookie.remove('popuptriggerFrom', { path: "/" });
		cookie.remove("selectedMainCat", { path: "/" });
    	cookie.remove("selectedMainCatId", { path: "/" });

	  }

   componentDidMount() {
	   
	   this.props.getStaticBlock();

		 Axios.get(apiUrl + 'cms/whats_happening?app_id='+appId).then(res => {

				if (res.data.status === "ok") {

					let whatshappenings_source = res.data?.common?.image_source;
				    let whatshappenings = res.data.result_set;

					this.setState({whatshappenings_source:whatshappenings_source, whatshappenings:whatshappenings })

				} else if (res.data.status === "error") {

				}

				return false;

			});
		  
		if(cookie.load('triggerAvlPop') != '' && cookie.load('triggerAvlPop') != undefined) {
			var availabilityId = cookie.load('triggerAvlPop');
			cookie.remove('triggerAvlPop', { path: "/" });
			this.chooseAvailability(availabilityId);
		}  
		  
		if(cookie.load('orderPopuptrigger') === 'Yes'){
			console.log('orderPopuptrigger not remove');
			  cookie.remove('orderPopuptrigger', { path: "/" });
			  $.magnificPopup.open({
				items: {
				  src: '.order_popup'
				},
				type: 'inline'
			  });
		}

		if(cookie.load('loginpopupTrigger') === 'Yes'){
			  cookie.save("loginpopupTrigger", 'fromcheckout', { path: "/" });
			  $.magnificPopup.open({
				items: {
				  src: '#login-popup'
				},
				type: 'inline'
			  });
		}

		if(cookie.load('loginpopupTrigger') === 'myaccount'){
			  $.magnificPopup.open({
				items: {
				  src: '#login-popup'
				},
				type: 'inline'
			  });
		}

		if(cookie.load('loginpopupTrigger') === 'myorders'){
			  $.magnificPopup.open({
				items: {
				  src: '#login-popup'
				},
				type: 'inline'
			  });
		}

		if(cookie.load('loginpopupTrigger') === 'myrewards'){
			  $.magnificPopup.open({
				items: {
				  src: '#login-popup'
				},
				type: 'inline'
			  });
		}

		if(cookie.load('loginpopupTrigger') === 'mypromotions'){
			  $.magnificPopup.open({
				items: {
				  src: '#login-popup'
				},
				type: 'inline'
			  });
		}

		if(cookie.load('loginpopupTrigger') === 'myvouchers'){
			  $.magnificPopup.open({
				items: {
				  src: '#login-popup'
				},
				type: 'inline'
			  });
		}
		
		if(cookie.load('cateringPay') === 'initial') {
			cookie.save('cateringPay', 'start');
			$.magnificPopup.open({
				items: {
					src: '#login-popup'
				},
				type: 'inline'
			});
		}
		  
		/*setTimeout(function(){ if ($(".address-list-main").length > 0) {
		$(".address-list-main").mCustomScrollbar();
		$(".address-list-error").mCustomScrollbar();
		} }, 1000);*/  
		  
	  }

    componentWillReceiveProps(PropsData) {
		  if(PropsData.staticblack !== this.state.staticblacks) {
			  let home_top_note = '';
			  let our_story = '';
			  if(Object.keys(PropsData.staticblack).length > 0) {
			    PropsData.staticblack.map((data, index) => {
				  if(data.staticblocks_slug === 'home-top-note') {
					  home_top_note = data.staticblocks_description;
				  }
				  if(data.staticblocks_slug === 'our-story') {
					  our_story = data;
				  }
				 
				});
			  }
			  home_top_note = (home_top_note !== '') ? Parser(home_top_note) : home_top_note;
			 
			  this.setState({home_top_note: home_top_note, our_story: our_story});
		  }
	  }

	  sateValChange = (field, value) => {
		if(field === 'cartflg') {
			this.setState({cartTriggerFlg: value});
		}
		if (field === "view_pro_data" && value !== "") {
		  this.setState(
		    { viewProductFlg: "yes", viewProductSlug: value },
		    function () {
		      this.openProDetailPopup();
		    }.bind(this)
		  );
		}
		if (field === "view_pro_upate" && value !== "") {
		  this.setState({ viewProductFlg: value });
		}
		if (field === "firstcategory" && value !== "") {
		  this.setState({ firstcategory: value });
		}
		if (field === "selectedMainCat") {
	      this.setState({ selectedMainCat: value });
	      cookie.save("selectedMainCat", value, { path: "/" });
	    }

	    if (field === "selectedMainCatId") {
	      this.setState({ selectedMainCatId: value });
	      cookie.save("selectedMainCatId", value, { path: "/" });
	    }
	  }
	  
	  chooseAvailability(availability){
		  
		var defaultAvilTy = cookie.load('defaultAvilablityId');
		if(defaultAvilTy!==availability){
		  var cartTotalItems = cookie.load('cartTotalItems');
			  cartTotalItems = (cartTotalItems != '' && cartTotalItems != undefined) ? parseInt(cartTotalItems) : 0;
		  if(defaultAvilTy === cateringId && cartTotalItems > 0) {
			  this.setState({nextavail:availability});
			  $.magnificPopup.open({
				items: {
				  src: '#warning-popup'
				},
				type: 'inline'
			  });
			  return false;
		  } else if(cookie.load('orderOutletId') != '' && cookie.load('orderOutletId') != undefined) {
			  this.setState({nextavail:availability});
			  $.magnificPopup.open({
				items: {
				  src: '#warning-popup'
				},
				type: 'inline'
			  });
			  return false;
		  }
		}
		
		var popupIdtxt = '';
		if(availability === deliveryId){
		  var singleOutltSlst = $('#delevery-popup').find('.self_outlet_inner li').length;
		  /*popupIdtxt = '#delevery-popup';*/
		  popupIdtxt = (singleOutltSlst === 1) ? '#delevery-postcode-popup' : '#delevery-popup';
		} else if(availability === madbarId){
		  //popupIdtxt = (singleOutltSlst === 1) ? '#delevery-postcode-popup' : '#delevery-popup';
		  popupIdtxt =  '#madbar-postcode-popup';

		}else if(availability === pickupId){
		  popupIdtxt = '#takeaway-popup';
		} else if(availability === cateringId) {
			cookie.save("defaultAvilablityId",cateringId);
		  	$.magnificPopup.close();
			this.props.history.push('/catering');
			return false;
		} else if(availability === reservationId) {
			cookie.save("defaultAvilablityId",reservationId);
		  	$.magnificPopup.close();
			this.props.history.push('/reservation');
			return false;
		 /* popupIdtxt = '#comingsoon-popup';*/
		}
		
		if(popupIdtxt !== '') {
			$.magnificPopup.open({
				items: {
				  src: popupIdtxt
				},
				type: 'inline'
			});
		}
	  

	  }
	  
	 
	  
	  checkActiveDiv(avlType) {
		  var clsTxt = "home-ordernow-single ";
		  var availability = cookie.load('defaultAvilablityId');
		  var orderOutletId = cookie.load('orderOutletId');
		  if(availability==avlType && orderOutletId !== '' && orderOutletId !== undefined){
			  clsTxt += 'active';
		  } else if(availability==avlType && (avlType === cateringId || avlType === reservationId)){
			  clsTxt += 'active';
		  } 
		  return clsTxt; 
	  }
	  
	  
	  listwhatshappenings() {
		  		  		  

		  let whatshappenings = this.state.whatshappenings;
		  let whatshappenings_source = this.state.whatshappenings_source;
		  
		  
		  if(Object.keys(whatshappenings).length > 0) {
			const mainMenu = whatshappenings.map((loaddata, index) => {

					if ((loaddata.gallery_image).length <= 4) {
					    var infinite = false;
					} else {
					    var infinite = true;
					}

					let Promotions = {
						dots: true,
						arrows: false,
						infinite: infinite,
						speed: 500,
						slidesToShow: 4,
						slidesToScroll: 1,
						autoplay: true,
						responsive: [
							{
							  breakpoint: 1024,
							  settings: {
								slidesToShow: 2,
								slidesToScroll: 1,
								adaptiveHeight: true,
							  },
							},
							{
							  breakpoint: 600,
							  settings: {
								slidesToShow: 1,
								slidesToScroll: 1,
							  },
							},
						  ]
					  };
				
					return (<div className="container-two" key={'wh'+(index+1)}>
							<div className="our-promotions-section">
								<h1>{loaddata.wh_name}</h1>
								
								<Slider {...Promotions}>
								
									{loaddata.gallery_image.map((loaddatagall, gallindex) => {
									  
									return(<div key={'whgall'+index+''+(gallindex+1)} onClick={() => this.setState({photoIndex:whatshappenings_source+"/"+loaddatagall.gallery_image, isOpen: true })}>
											
											
										{callImage(whatshappenings_source, loaddatagall.gallery_image, 315, 447, timThumpUrl)}
										
									  </div>);
								  
								})}

								</Slider>
								
							</div>
						</div>);
				
			});
			
			return mainMenu;
		  } else {
			return null;
		  }
	  }

	  render() {

		var settingsFeature = {
			items: 4,
			autoplay: false,
			margin: 18,
			loop: false,
			nav: false,
			dots: false,
			responsive: {
			  0: {
				items: 1,
			  },
			  580: {
				items: 2,
			  },
			  780: {
				items: 3,
			  },
			  1280: {
				items: 4,
			  },
			},
		  };

const { isOpen, photoIndex } = this.state; 	  	  	

		return (<div>
				{this.state.home_top_note}
			   <div className="home-main-div" >
					
					{/* Header section */}
					<Header homePageState={this.state} cartTriggerFlg={this.state.cartTriggerFlg} sateValChange={this.sateValChange} />
				
				
					{/* Home banner section */}
					<HomeBanner />

					{/* Home Category section - start */}

					<section className="home-category">
						<div className="container-full">
							<div className="home-category-inner">
								<MenuDNavigation
				                    {...this.props}
				                    productState={this.state}
				                    sateValChange={this.sateValChange}
				                />
							</div>

						</div>
					</section>

					{/* Home Category section - End */}

					<section className="home-feature pd-top-50">
						<div className="container">
							<FeaturedProducts 
							{...this.props}
							sateValChange={this.sateValChange}  />
						</div>
					</section>
				
					{/* Join Us section - start */}
					<section className="home-join pd-top-50">
						<div className="container-full" >
							<div className="join-us-section">
								<div className="lft-sec-prt">
									{/* <img src={joinusImg} /> */}
								</div>
								<div className="lft-sec-prt-main">
									<div className="mid-sec-prt">
										<h4>WELCOME TO</h4>
										<h2>Your Online Liquor Store</h2>
										<a href="#signup-popup" className="open-popup-link" title="ORDER Now"> <span>ORDER Now</span></a>
									</div>
									<div className="rgt-sec-prt">
										 <img src={joinusIcon} /> 
										{/*<p>Earn Rewards, Enjoy Deals, Earn Promos <br/>& Have Lots Of Fun</p>*/}
										 {/* {!cookie.load("UserId") &&
										<a href="#signup-popup" className="open-popup-link" title="Apply Now"> <span>ORDER Now</span></a>
										}  */}
										{/* <a href={"terms-conditions"} title="Member Benefits"><span>Member Benefits</span></a> */}
									</div>
								</div>
							</div>
						</div>
					</section>
					{/* Join Us section - End */}


					{/* About Us section - Start */}
					<section className="about-one pd-top-50">
						<div className="container">
							{Object.keys(this.state.our_story).length > 0 && (
								<div className="about-row">
									<div className="about-left-container">
										<div className="about-left-one">
											{Parser(this.state.our_story.staticblocks_description)}
										</div>
									</div>
									<div className="about-right-container">
										{this.state.our_story.gallery_images.map((loaddatagall, gallindex) => {
										return (<div className="item" key={gallindex}>
											<figure className="rounded shadow">
												<img src={this.state.our_story.gallery_image_path+loaddatagall} />
											</figure>
										</div>);
										})}
									</div>
								</div>
							)}
						</div>
					</section>
					<section className="our-promotions pd-top-50">
					    <div className="container" > 
						    {/*<PromotionProducts sateValChange={this.sateValChange}  />*/}
					    	{this.listwhatshappenings()}
					    </div>
					</section>
					<Testimonial />
			
			{isOpen && (
				<Lightbox
					mainSrc={photoIndex}
					onCloseRequest={() => this.setState({ isOpen: false })}            
				/>
			)}
			{/* Footer section */}
			<Footer />					
			</div>
		</div>
		)
	  }	
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  var tempArr = Array();
  var menu_slug = "";
  var menu_type = "";
  var navigateRst = Array();
  var navigateCmn = Array();
  var selectedCatry = "";
  if(Object.keys(state.staticblack).length > 0) {
		 if(state.staticblack[0].status === 'ok') {
			 blacksArr = state.staticblack[0].result_set;
		 }
  }

  if (Object.keys(state.productmenunav).length > 0) {
    var tempArr = !("menuNavigation" in state.productmenunav[0])
      ? Array()
      : state.productmenunav[0].menuNavigation;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        navigateRst = tempArr[0].result_set;
        navigateCmn = tempArr[0].common;
        if (tempArr[0].result_set[0].menu_type == "main") {
          selectedCatry = tempArr[0].result_set[0].category_name;
          menu_slug = tempArr[0].result_set[0].pro_cate_slug;
          menu_type = "category";
        } else {
          selectedCatry = tempArr[0].result_set[0].subcategory_name;
          menu_slug = tempArr[0].result_set[0].pro_subcate_slug;
          menu_type = "subcategory";
        }
      }
    }
  }

  return {
    staticblack: blacksArr,
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
    selectedCatry: selectedCatry,
    selectedNavigation: menu_slug,
    selectedSlugType: menu_type
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
    getMenuNavigationList: (outletID) => {
      dispatch({ type: GET_MENU_NAVIGATION, outletID });
    }
  }
}

export default connect(mapStateTopProps, mapDispatchToProps)(Home);
