import React from 'react';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import cookie from "react-cookies";

import CardSection from './CardSection';
import { apiUrl,appId,baseUrl,stripeReference } from "../Helpers/Config";
import $ from 'jquery'; 
import Axios from 'axios';
import { ReactSession }  from 'react-client-session';

var qs = require('qs');

export default function CheckoutForm() {

  
  const stripe = useStripe();
  const elements = useElements();
   
  const handleSubmit = async (event) => {

    $(".paymentsection").hide();
    $(".paymentloading").fadeIn();
    
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    const result = await stripe.confirmCardPayment(cookie.load("clientSecret"), {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: cookie.load("UserFname"), 
        }, 
      }   
    });  
 
    if (result.error) {
      $(".paymentsection").fadeIn();
      $(".paymentloading").fadeOut();
      $(".show_cart_error_msg_ini").html(result.error.message);
      $(".show_cart_error_msg_ini").fadeIn().delay(6000).fadeOut();
      // Show error to your customer (e.g., insufficient funds)
      console.log(result.error.message);
    } else {  

      // The payment has been processed!
      if (result.paymentIntent.status === 'succeeded') { 

      var postObject = ReactSession.get("postObject")+'&connect_stripe_account_id='+cookie.load("stripe_account_id")+'&connect_total_amount='+cookie.load("connect_total_amount")+'&connect_platform_fee='+cookie.load("platform_fee")

     Axios
      .post(
        apiUrl + "ordersv1/submit_order",
        postObject,
        
      )
      .then((res) => {

        if (res.data.status === "ok") {
              console.log(res.data);

          var localOrderNo = res.data.common.local_order_no;
          //var subscription = res.data.common.subscription;
          var orderID = res.data.common.order_id;

           var customerId =
            typeof cookie.load("UserId") === "undefined"
              ? ""
              : cookie.load("UserId");

            var  postObject = {
              "app_id": appId, 		 
              "order_id": orderID,
              "stripe_response": JSON.stringify(result.paymentIntent),
              "customerId": customerId,
              "payment_reference": stripeReference,
            }   

            Axios.post(apiUrl+'paymentv4/statusUpdate', qs.stringify(postObject)).then(function (response) {
              $(".paymentsection").fadeIn();  
              $(".paymentloading").fadeOut();
              window.location = baseUrl+"thankyou/" + localOrderNo;
            })
            .catch(function (error) { 
              $(".paymentsection").fadeIn(); 
              $(".paymentloading").fadeOut();
              console.log(error);
            });

        }else{
        $(".paymentsection").fadeIn(); 
        $(".paymentloading").fadeOut();
        $(".show_cart_error_msg").html('Error code: 1001 Oops! Unable to processing your order. Please try again.');
        $(".show_cart_error_msg").fadeIn().delay(6000).fadeOut();
        $(document).find('.mfp-close').trigger('click');
        }

      }).catch(function (error) { 
        $(".paymentsection").fadeIn(); 
        $(".paymentloading").fadeOut();
        $(".show_cart_error_msg").html('Error code: 1001 Oops! Unable to processing your order. Please try again.');
        $(".show_cart_error_msg").fadeIn().delay(6000).fadeOut();
        $(document).find('.mfp-close').trigger('click');
        console.log(error);
      });     

      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardSection /><br /> 
      <button disabled={!stripe} className="btn btn-block btn_pink">Complete</button>
    </form>
  );
} 