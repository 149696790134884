import { all } from 'redux-saga/effects'
import { watchGetSettings } from './settings';
import { watchGetStaticBlack } from './staticblack';
import { watchGetCartDetail, watchUpdateCartDetail, watchDeleteCartDetail, watchDestroyCartDetail } from './cartlist';
import { watchGetPickupOutlets} from './outlets';
import { watchGetAllOutlets } from './alloutlets';
import { watchGetZonedetail } from './zonedetail';
import { watchGetBanner } from './banner';
import { watchGetFeaturePro } from './featureproduct';
import { watchGetPromotionPro } from "./promotionproduct";
import { watchGetProducts } from './product';
import { watchGetMenuNavigation } from "./productmenunav";
import { watchGetProductDetail } from './productdetail';
import { watchGetOrderDetail } from './orderdetail';
import { watchGetAddonPro } from './addonproduct';
import { watchGetLoginData } from './login';
import { watchGetFbLoginData } from './fblogin';
import { watchGetCustomerDetail } from './customerdetail';
import { watchGetForgetPassword } from './forgetpassword';
import { watchGetRegistration } from './registration';
import { watchGetChangePassword } from './changepassword';
import { watchGetUpdateCustomerProfile } from './updatecustomerprofile';
import { watchGetCorderDetail } from './corderdetail';
import { watchGetPorderDetail } from './porderdetail';
import { watchGetPrintOrder } from './printorder';
import { watchGetOrderHistory } from './orderhistory';
import { watchGetPromotionList } from './promotionlist';
import { watchGetPromotionReceipt } from './promotionreceipt';
import { watchGetApplyPromotion } from './applypromotion';
import { watchGetActivityCount } from './activitycount';
import { watchGetRewardEarned } from './rewardearned';
import { watchGetRewardRedeem } from './rewardredeem';
import { watchGetRequestpage } from './pages';

import { watchGetMenuData } from './menudata';
import { watchGetContactData } from './contactdata';

import { watchGetSecAddress, watchAddSecAddress } from './secondaryaddress';
import { watchGetFavouriteproducts } from "./favouriteproduct";
import { watchGetFavouriteproductslist } from "./favouriteproductlist";
import { watchGetSocialsetting } from './socialsettings';
import { watchGetTestimonial } from "./testimonial";

export default function* () {
  yield all([
    watchGetSettings(),
    watchGetStaticBlack(),
    watchGetCartDetail(),
    watchUpdateCartDetail(),
    watchDeleteCartDetail(),
    watchDestroyCartDetail(),
    watchGetPickupOutlets(),
    watchGetAllOutlets(),
    watchGetZonedetail(),
    watchGetBanner(),
    watchGetFeaturePro(),
    watchGetPromotionPro(),
    watchGetMenuNavigation(),
    watchGetProducts(),
    watchGetProductDetail(),
    watchGetAddonPro(),
    watchGetOrderDetail(),
	watchGetLoginData(),
    watchGetFbLoginData(),
    watchGetCustomerDetail(),
    watchGetForgetPassword(),
    watchGetRegistration(),
    watchGetChangePassword(),
    watchGetUpdateCustomerProfile(),
    watchGetCorderDetail(),
    watchGetPorderDetail(),
    watchGetPrintOrder(),
    watchGetOrderHistory(),
    watchGetPromotionList(),
    watchGetPromotionReceipt(),
    watchGetApplyPromotion(),
	watchGetActivityCount(),
    watchGetRewardEarned(),
    watchGetRewardRedeem(),
	watchGetRequestpage(),
    watchGetMenuData(),
    watchGetContactData(),
  

	watchGetSecAddress(),
	watchAddSecAddress(),
  watchGetFavouriteproducts(),
  watchGetFavouriteproductslist(),
	watchGetSocialsetting(),
  watchGetTestimonial(),
  ])
}
