export const appId        = "A69CA062-BB6D-4C79-8203-A2C2E2972850"; /*Madbar NinjaOS*/
//export const appId        = "6D5AC33B-5792-4F47-A7FE-66F2059113CD"; /*Madbar prmobuddy*/

export const apiUrl       = "https://ccpl.ninjaos.com/api/";
export const apiUrlV2     = "https://ccpl.ninjaos.com/apiv2/";
export const apiUrlPro    = "https://ccpl.ninjaos.com/ninjapro/";
export const apiUrlNotify = "https://ccpl.ninjaos.com/Pushorder/";
export const timThumpUrl  = "https://ccpl.ninjaos.com/timthumb.php?src=";
export const blogImageUrl = "https://ccpl.ninjaos.com/media/dev_team/blog/";
export const stripeImage  = "";
export const webUrl      = "https://ccpl.ninjaos.com";
export const tagImageUrl = "https://ccpl.ninjaos.com/media/dev_team/tag/";

//export const baseUrl      = "http://localhost:3000/";
//export const baseUrl      = "https://www.madbar.com/";
export const baseUrl      = "https://madbar.promobuddy.asia/";

/*export const apiUrl       = "https://ccpl.promobuddy.asia/api/";
export const apiUrlV2     = "https://ccpl.promobuddy.asia/apiv2/";
export const apiUrlPro    = "https://ccpl.promobuddy.asia/ninjapro/";
export const apiUrlNotify = "https://ccpl.promobuddy.asia/Pushorder/";
export const timThumpUrl  = "https://ccpl.promobuddy.asia/timthumb.php?src=";
export const blogImageUrl = "https://ccpl.promobuddy.asia/media/dev_team/blog/";
export const stripeImage  = "";
export const webUrl      = "https://ccpl.promobuddy.asia";
export const tagImageUrl = "https://ccpl.promobuddy.com/media/dev_team/tag/";*/
   


export const deliveryId  = "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11";
export const madbarId  = "471745F6-0AEC-4641-9802-6DA1968D5D79";
export const pickupId    = "718B1A92-5EBB-4F25-B24D-3067606F67F0";
export const cateringId    = "EB62AF63-0410-47CC-9464-038E796E28C4";
export const reservationId    = "79FA4C7F-75A1-4A95-B7CE-81ECA2575363";
export const eventsId    = "AF70EE93-2B8B-474D-9078-044F259637F3";
export const dineinId = "EF9FB350-4FD4-4894-9381-3E859AB74019";
export const CountryTxt   = "Singapore";
export const noimage = "/img/product-noimg.jpg";
export const stripeCompany    = "Madbar";

export const fbAppId    = "595287311396430";

export const stripeKey    = "pk_test_hLfht4KEShHlACDG9mBfrPkv";
export const stripeEmail    = "dev@jankosoft.com";
export const stripeReference    = "madbar";
export const stripeDesc    = "My Checkout";
export const stripeCurrency    = "SGD";
export const companyname    = "Madbar";
export const mapcountry = "sg";
export const currencySybmol =  "$";

 
export const getHeader = function() {
		
		
	let header = {
	  headers: { 'X-API-KEY': 'D04Ea1c5-b19c-4B58-b2e9-88F5C0456432' }
	}

	/*if(cookie.load('Token')!='' && cookie.load('Token')!=undefined) {
	  header.headers.Auth = cookie.load('Token')
	}*/
	
	if(localStorage.getItem('BeaerToken') !== undefined && localStorage.getItem('BeaerToken') !== '' && localStorage.getItem('BeaerToken') !== null) {

		 header.headers.Auth = localStorage.getItem('BeaerToken') 
	}
	
	
	return header;

}; 
