/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link} from 'react-router-dom';
import Axios from 'axios';
import cookie from 'react-cookies';

import Slider from "react-slick";

import Parser from "html-react-parser";
import { setMinutes, setHours, getDay, format } from "date-fns";

import { appId, apiUrl, apiUrlV2, deliveryId, timThumpUrl } from "../../Helpers/Config";
import { getReferenceID, stripslashes, showPriceValue, shorten, callImage, showLoader, hideLoader, showCustomAlert, showCartLst, showAlert, removePromoCkValue } from "../../Helpers/SettingHelper";

import { GET_FEATUREPRO } from '../../../actions';

var qs = require('qs');

class FeaturedProducts extends Component {
	constructor(props) {
		super(props);
		this.state = {
		 
		}
	  }
	  
	  componentDidMount() {

		this.props.getFeatureProList();
	  }
	  
	  proQtyAction(indxFlg, actionFlg) {
		  var proqtyInput = $("#proIndex-"+indxFlg).find('.proqty_input').val();
			proqtyInput = parseInt(proqtyInput);

			if(actionFlg === "decr" && proqtyInput == 1){
	      $("#proIndex-" + indxFlg)
	          .find(".smiple_product_lk")
	          .show();
	      $("#proIndex-" + indxFlg)
	        .find(".addcart_done_maindiv")
	        .hide();
	      $("#proIndex-" + indxFlg)
	      .find(".product-list-main-div")
	      .removeClass('add-cart');        
	    }

		  if (actionFlg === 'decr') {
			  proqtyInput = (proqtyInput > 1) ? proqtyInput - 1 : proqtyInput;
		  } else {
			  proqtyInput = proqtyInput + 1;
		  }
		  $("#proIndex-"+indxFlg).find('.proqty_input').val(proqtyInput);
	  }

	  getProductSplPrice(productDetail) {
    var productSpecialPrice =
      productDetail.product_special_price !== ""
        ? parseFloat(productDetail.product_special_price)
        : 0;
    if (
      productSpecialPrice > 0 &&
      productDetail.product_special_price_from_date !== "" &&
      productDetail.product_special_price_to_date !== ""
    ) {
      var datetime = new Date();
      var crtDatefrmtTxt = format(datetime, "yyyy-MM-dd");
      var crtDatefrmtObj = new Date(crtDatefrmtTxt);
      var proSplFromdate = new Date(
        productDetail.product_special_price_from_date
      );
      var proSplTodate = new Date(productDetail.product_special_price_to_date);
      if (proSplFromdate <= crtDatefrmtObj && proSplTodate >= crtDatefrmtObj) {
        return (
          <div>
            <div className="product-spl-price">
              <h3>{Parser(showPriceValue(productDetail.product_price))}</h3>
              {/* <h3>{"S$" + productDetail.product_price}</h3> */}
            </div>
            <div className="product-price">
              {productDetail.product_special_price > 0 ? (
                <h3>
                  {Parser(showPriceValue(productDetail.product_special_price))}
                </h3>
              ) : (
                <h3 className="price">{Parser(showPriceValue(productDetail.product_price))}</h3>
              )}
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <div className="product-spl-price">
              <h3></h3>
            </div>
            <div className="product-price">
              <h3>{Parser(showPriceValue(productDetail.product_price))}</h3>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div>
          <div className="product-spl-price">
            <h3></h3>
          </div>
          <div className="product-price">
            <h3>{Parser(showPriceValue(productDetail.product_price))}</h3>
          </div>
        </div>
      );
    }
  }
	  
	  /* add to cart */
	  addToCartSimple(productDetail, actionFlg) {
		
	    var avilablityId = cookie.load('defaultAvilablityId');
	    var orderOutletId = cookie.load('orderOutletId');
		if(avilablityId!=='' && orderOutletId != undefined && orderOutletId !=='' && orderOutletId != undefined){
			
			var IndexFlg = productDetail.product_primary_id;
		
			if (actionFlg === 'initial') {
				$("#proIndex-"+IndexFlg).find('.smiple_product_lk').hide();
				$("#proIndex-"+IndexFlg).find('.addcart_done_maindiv').show();
				return false;
			} else {
				showLoader('proIndex-'+IndexFlg,'Idtext');
				var availabilityId = cookie.load("defaultAvilablityId");
				var availabilityName = (availabilityId === deliveryId) ? "Delivery" : "Pickup";
				var isAddonProduct = 'No';
				var productId = productDetail.product_id
				var customerId = (typeof cookie.load('UserId') === 'undefined') ? '' : cookie.load('UserId');
				var proqtyQty = $("#proIndex-"+IndexFlg).find('.proqty_input').val();
				var datetime = new Date();
	      var crtDatefrmtTxt = format(datetime, "yyyy-MM-dd");
	      var crtDatefrmtObj = new Date(crtDatefrmtTxt);
	      var proSplFromdate = new Date(
	        productDetail.product_special_price_from_date
	      );
	      var proSplTodate = new Date(productDetail.product_special_price_to_date);
				var specialprice_applicable =
          ((productDetail.product_special_price !== '0.00' && proSplFromdate <= crtDatefrmtObj && proSplTodate >= crtDatefrmtObj)? 'Yes' : '' );
				
				var postObject = {};
				postObject = {
					'app_id': appId,
					"product_id": productId,
					"product_qty": proqtyQty,
					"product_type": 1,
					"availability_id": availabilityId,
					"availability_name": availabilityName,
					"isAddonProduct": isAddonProduct,
					"specialprice_applicable": specialprice_applicable,
					"reference_id": (customerId === '') ? getReferenceID() : '',
					"customer_id": customerId
				};

				Axios.post(apiUrlV2 + "cart/simpleCartInsert", qs.stringify(postObject)).then(res => {
					hideLoader('proIndex-'+IndexFlg,'Idtext');
					$("#proIndex-"+IndexFlg).find('.addcart_done_maindiv').hide();
					$("#proIndex-"+IndexFlg).find('.smiple_product_lk').show();
					if (res.data.status === "ok") {
						this.props.sateValChange('cartflg', 'yes');
						showCustomAlert('success','Great choice! Item added to your cart.');
						/*showCartLst();*/
						removePromoCkValue();
						this.handleShowAlertFun('success','Great choice! Item added to your cart.');
						return false;
					} else if (res.data.status === "error") {
						var errMsgtxt = (res.data.message !== '') ? res.data.message : "Sorry! Products can`t add your cart.";
						showCustomAlert('error',errMsgtxt);
					}
				});
				
			}
		  
		} else {
			cookie.save("popuptriggerFrom",'FeaturedPro', { path: "/" });
			$.magnificPopup.open({
				items: {
				  src: '.order_popup'
				},
				type: 'inline'
			});
			
		}
		
	  }

	  viewProDetail(productDetail, event) {
    event.preventDefault();

    var avilablityId = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (
      avilablityId !== "" &&
      orderOutletId != undefined &&
      orderOutletId !== "" &&
      orderOutletId != undefined
    ) {
      var productSlug = productDetail.product_slug;
      if (productSlug !== "") {
        /*  $("#proIndexfea-" + productDetail.product_primary_id).addClass(
          "active"
        ); */
        this.props.history.push(
          "/products/" +
            productDetail.pro_cate_slug +
            "/" +
            productDetail.pro_subcate_slug +
            "/" +
            productDetail.product_slug
        );
        // this.props.sateValChange("view_pro_data", productSlug);
      }
    } else {
      cookie.save("popuptriggerFrom", "FeaturedPro", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#order-popup",
        },
        type: "inline",
      });
    }
    return false;
  }
	  
	  handleShowAlertFun(header,msg) {
			var magnfPopup = $.magnificPopup.instance;
			showAlert(header, msg, magnfPopup);
			$.magnificPopup.open({
			  items: {
				src: '.alert_popup'
			  },
			  type: 'inline'
			});
	  }

	  render() {

		let featureproArr = this.props.featureproduct;
		let featureprolist = [];
		let featureproimagesource = '';

		let datetime = new Date();
    let crtDatefrmtTxt = format(datetime, "yyyy-MM-dd");

		let feaproduct_length = 0;

		if(Object.keys(featureproArr).length > 0) {

			if(featureproArr[0].status === 'ok') {

				featureprolist = featureproArr[0].result_set;

				featureproimagesource = featureproArr[0].common.image_source;
				feaproduct_length = (featureprolist)?parseInt(featureprolist.length):0;
				feaproduct_length = (feaproduct_length > 3)?4:feaproduct_length;

			}
		}

		var settingsGallery = {
			infinite: true,
            slidesToShow: feaproduct_length,
			autoplay: true,
			autoplaySpeed: 4000,
			pauseOnHover: true,
           	slidesToScroll: 1,
			dots:true,
			arrows: false,
			responsive: [{
				breakpoint: 1191,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4,
					infinite: false

				}
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: false
				}
			},
			{
				breakpoint: 680,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					infinite: false
				}
			},
			{
				breakpoint: 380,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: false
				}
			}
			]
        };
		
		return (
			    <>
					{(Object.keys(featureprolist).length > 0)?
					 
					<div className="feature-inner">
						<div className="home-feature-heading">
							<h4>Featured Products</h4>
							<a href="/products">shop all</a>
						</div>

						<Slider {...settingsGallery}>
						{ 
						(featureprolist).map(
							(featurepro,index) => {
								var prodivId = "proIndex-"+featurepro.product_primary_id;
								var comboProId = "comboPro-" + featurepro.product_slug;
								return (

									<div className="products-single-li" id={prodivId} key={index}>
										<div className="products-image-div">
										{/*<span className="whishlist-img"></span>*/}
										{featurepro.product_tag_info !== "" &&
                      featurepro.product_tag_info !== null && (
                        <div className="sale-flag-sash">
                          <span className="sale-text">{featurepro.product_tag_info}</span>
                        </div>
                    )}
										{callImage(featureproimagesource, featurepro.product_thumbnail, 314, 306, timThumpUrl)}
										</div>
										<div className="product-info-div">
											<div className="product-title-maindiv">
												<div className="product-title">
													<h3>{stripslashes(featurepro.product_name)}</h3>
												</div>
											
												<div className="product-item-tag">
													{featurepro.product_special_price != '0.00' && featurepro.product_special_price_from_date <= crtDatefrmtTxt && featurepro.product_special_price_to_date >= crtDatefrmtTxt && (
														<ul>
															<li>special</li>
														</ul>
													)}
												</div>

												<div className="product-short-description">
													<p>{(featurepro.product_short_description!='')?Parser(stripslashes(shorten(featurepro.product_short_description))):''}</p>
												</div>
											</div>

											<div className="products-ordernow-action">
											{/*{this.getProductSplPrice(featurepro)}*/}
											<div className="product-price">
												{featurepro.product_special_price != '0.00'
													&& featurepro.product_special_price_from_date <= crtDatefrmtTxt && featurepro.product_special_price_to_date >= crtDatefrmtTxt && ( parseFloat(featurepro.product_price) > 0 ? (
														<span className="price">
														{showPriceValue(featurepro.product_price)}
														</span>
													)
													: parseFloat(featurepro.product_cost) > 0 && (
														<span className="price">
														{showPriceValue(featurepro.product_cost)}
														</span>
													))}
												<h3>
													{featurepro.product_special_price != '0.00' && featurepro.product_special_price_from_date <= crtDatefrmtTxt && featurepro.product_special_price_to_date >= crtDatefrmtTxt
													? showPriceValue(
														featurepro.product_special_price,
														"Y"
														)
													: showPriceValue(featurepro.product_price)}
												</h3>
											</div>

											{featurepro.product_stock > 0 ||
											featurepro.product_stock === null ? (
												featurepro.product_type === "1" ? (
												<a
													href="/"
													onClick={this.viewProDetail.bind(
													this,
													featurepro
													)}
													title="Product Details"
													id={comboProId}
													className="button order_nowdiv compo_product_lk"
												>
													Add to cart
												</a>
												) : (
												<a
													href="/"
													onClick={this.viewProDetail.bind(
													this,
													featurepro
													)}
													title="Product Details"
													id={comboProId}
													className="button order_nowdiv compo_product_lk"
												>
													Order Now
												</a>
													)
												) : (
													<a
													className="button disabled disbl_href_action"
													href="/"
													>
													Sold Out
													</a>
											)}

											</div>


											{/*<div className="addcart_row addcart_done_maindiv">
													<div className="qty_bx">
														<span className="qty_minus" onClick={this.proQtyAction.bind(this,featurepro.product_primary_id, 'decr')}>-</span>
														<input type="text" className="proqty_input" readOnly value="1" />
														<span className="qty_plus" onClick={this.proQtyAction.bind(this,featurepro.product_primary_id, 'incr')}>+</span>
													</div>
													<button className="btn btn_black order_done" onClick={this.addToCartSimple.bind(this,featurepro,'done')}>Done</button>
											</div>*/}
												  
											
										</div>
									</div>
									)
								  }
								)
								}
						</Slider>
					</div>
				:''}
				</>

			   )
	  }	
}

const mapStateTopProps = (state) => {

  return {
    featureproduct: state.featureproduct
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFeatureProList: () => {
      dispatch({ type: GET_FEATUREPRO });
    },
  }
}

export default connect(mapStateTopProps, mapDispatchToProps)(FeaturedProducts);